// .cache/gatsby-mdx/wrapper-components/{wrapper-filepath-hash}-{scope-hash}.js
import React from 'react';
import { MDXScopeProvider } from 'gatsby-mdx/context';

import __mdxScope_0 from '/Users/matuzalemteles/Documents/clay/clayui.com/.cache/gatsby-mdx/remote-mdx-dir/216fd2886a4eb651dcb7b06d675ecd19.js';

import OriginalWrapper from '/Users/matuzalemteles/Documents/clay/clayui.com/src/templates/docs.js';

import { graphql } from 'gatsby';

// pageQuery, etc get hoisted to here
export const pageQuery = graphql`
    query($slug: String!) {
        mdx(fields: { slug: { eq: $slug } }) {
            excerpt
            timeToRead
            frontmatter {
                title
            }
            code {
                body
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            excerpt
            timeToRead
            html
            htmlAst
            frontmatter {
                title
            }
        }
    }
`;;

export default ({children, ...props}) => <MDXScopeProvider __mdxScope={{...__mdxScope_0}}>
  <OriginalWrapper
    {...props}
  >
    {children}
  </OriginalWrapper>
</MDXScopeProvider>